export class AuthModel {
  authToken: string;
  refreshToken: string;
  expiresIn: Date;

  constructor(data: any) {
    this.authToken = data.access_token;
    this.refreshToken = data.refresh_token;
    const expiresAt = new Date();
    expiresAt.setSeconds(expiresAt.getSeconds() + data.expires_in);
    this.expiresIn = expiresAt;
  }
}
