import {Injectable} from '@angular/core';
import {InlineNotification} from './inline-notification/inline-notification.model';
import {ToastrService} from 'ngx-toastr';
import {from, Observable} from 'rxjs';
import {switchMap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {
  constructor() {}
}

export function fetchObservable<T>(
  method: string,
  url: string,
  body?: BodyInit,
  headers?: Headers
): Observable<any> {
  if (headers == null) {
    headers = new Headers({
      'Content-Type': 'application/json'
    });
  }
  return from(fetch(url, {method, headers, body})).pipe(
    switchMap((response) => {
      if (response.status === 401) {
        throw response;
      }
      return response.json();
    })
  ) as Observable<T>;
}

export function downloadFile(response: any, filename?: string) {
  let contentType: string;

  if (response.headers.keys().includes('content-disposition')) {
    filename = response.headers
      .get('content-disposition')
      .split('filename=')[1]
      .replace(/"/g, '');
  }

  if (response.headers.keys().includes('content-type')) {
    contentType = response.headers.get('content-type');
  }

  if (filename && contentType) {
    const blob = new Blob([response.body], {
      type: contentType
    });

    if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
      (window.navigator as any).msSaveOrOpenBlob(blob, filename);
    } else {
      const a = document.createElement('a') as any;
      a.href = URL.createObjectURL(blob);
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  }
}

export function showToastFromInlineNotification(
  toastr: ToastrService,
  notification: InlineNotification
) {
  if (notification) {
    switch (notification.status) {
      case 'success': {
        toastr.success(notification.message);
        break;
      }
      case 'warning': {
        toastr.warning(notification.message);
        break;
      }
      case 'danger': {
        toastr.error(notification.message);
        break;
      }
      default: {
        toastr.show(notification.message);
      }
    }
  }
}

export function getHostFromUrl(): string | null {
  const result: RegExpMatchArray | null = location.origin.match(
    'http(s)?:\\/\\/(?<host>[A-Za-z0-9.-]*)((:\\d*))?(.*)?'
  );
  const groups = result.groups;
  if (groups == null || !('host' in groups)) {
    return null;
  }

  return groups.host;
}
