// USA
export const locale = {
  lang: 'en',
  data: {
    translator: {
      select: 'Select your language'
    },
    menu: {
      new: 'new',
      actions: 'Actions',
      createPost: 'Create New Post',
      pages: 'Pages',
      features: 'Features',
      apps: 'Apps',
      dashboard: 'Dashboard',
      merchant: {
        companies: 'Companies',
        pointsOfSale: 'Points of Sale',
        devices: 'Devices'
      },
      transactions: {
        transactions: 'Transactions',
        statements: 'Statements'
      },
      payouts: {
        payouts: 'Payouts'
      },
      builder: 'Builder',
      chooseLabel: 'Choose Label',
      clickToLearnMore: 'Click to learn more...',
      customer: 'Customer',
      partner: 'Partner',
      suplier: 'Supplier',
      member: 'Member',
      members: 'Members',
      staff: 'Staff',
      addNew: 'Add new',
      order: 'Order',
      event: 'Event',
      report: 'Report',
      post: 'Post',
      file: 'File',
      project: 'Project',
      record: 'Record',
      moreOptions: 'More options',
      learnMore: 'Learn more',
      newGroup: 'New Group',
      contacts: 'Contacts',
      groups: 'Groups',
      calls: 'Calls',
      settings: 'Settings',
      help: 'Help',
      privacy: 'Privacy'
    },
    notifications: {
      userNotifications: 'User Notifications',
      alerts: 'Alerts',
      events: 'Events',
      logs: 'Logs',
      exports: 'Exports',
      reload: 'Reload',
      noNew: 'All caught up!<br />No new notifications.',
      new: 'new',
      loadingNotifications: 'Loading notifications ...'
    },
    userProfile: {
      userProfile: 'User Profile',
      singOut: 'Sign Out'
    },
    dashboard: {
      companies: 'Companies',
      pointOfSales: 'Point of sales',
      devices: 'Devices',
      payouts: 'Payouts',
      allTransactions: 'All transactions',
      todaysTransactions: 'Today`s transactions'
    },
    featureRequests: 'Feature Requests',
    companyList: 'Company list',
    pointsOfSaleList: 'Points of sale list',
    deviceList: 'Device list',
    goCryptoCompanyList: 'GoCrypto Company list',
    transactionsList: 'Transaction list',
    statementList: 'Statement list',
    paymentList: 'Payout list',
    table: {
      group: 'Group',
      company: 'Company',
      id: 'ID',
      type: 'Type',
      createdAt: 'Created at',
      actions: 'Actions',
      noRecordsFound: 'No records found',
      fullName: 'Full name',
      country: 'Country',
      terminalId: 'Terminal ID',
      serialNumber: 'Serial number',
      ofPointsOfSale: '# of points of sale',
      pos: 'Point of Sale',
      pointsOfSale: 'Points of sale',
      status: 'Status',
      createAt: 'Create at',
      approvedAt: 'Approved at',
      activatedAt: 'Activated at',
      devices: 'Devices',
      numberOfDevices: '# Devices',
      noCompany: 'No company',
      editCompany: 'Edit Company',
      editPointOfSale: 'Edit Point of Sale',
      paymentMethod: 'Payment Method',
      amount: 'Amount',
      currency: 'Currency',
      paymentStatus: 'Payment Status',
      totalAmount: 'Total amount',
      isSent: 'Is sent',
      fees: 'Fees',
      totalPayableAmount: 'Total payable amount',
      periodType: 'Period Type',
      from: 'From',
      to: 'To',
      paymentFlow: 'Payment flow',
      isActive: 'Is Active',
      isPaired: 'Is paired',
      unpaired: 'Unpaired',
      pairedAt: 'Paired At',
      paymentProcessor: 'Payment Processor'
    },
    buttons: {
      newCompany: 'New Company',
      export: 'Export',
      cancel: 'Cancel',
      pushSettings: 'Push settings',
      terminate: 'Terminate',
      update: 'Update',
      approve: 'Approve',
      approved: 'Approved',
      saveChanges: 'Save Changes',
      reset: 'Reset',
      unpair: 'Unpair',
      deactivate: 'Deactivate',
      activate: 'Activate',
      deleteDevice: 'Delete device',
      create: 'Create',
      delete: 'Delete',
      close: 'Close',
      submit: 'Submit',
      back: 'Back',
      next: 'Next',
      disable: 'Disable',
      disabled: 'Disabled'
    },
    inputs: {
      search: 'Search',
      searchInAllFields: '<b>Search</b> in all fields',
      filterByStatus: 'Filter by Status',
      filterByStatusHtml: '<b>Filter</b> by Status',
      filterByCountry: 'Filter by Country',
      filterByCountryHtml: '<b>Filter</b> by Country',
      filterByType: 'Filter by Type',
      filterByTypeHtml: '<b>Filter</b> by Type',
      filterByCurrency: 'Filter by Currency',
      filterByCurrencyHtml: '<b>Filter</b> by Currency',
      filterByMethod: 'Filter by Method',
      filterByMethodHtml: '<b>Filter</b> by Payment method',
      filterBySent: 'Filter by Sent',
      filterBySentHtml: '<b>Filter</b> by Sent'
    },
    companyCard: {
      status: 'Status',
      contactEmail: 'Contact Email',
      accountingEmail: 'Accounting Email',
      configuration: 'Configuration',
      pushSettings: 'Push settings',
      navi: {
        unavailableUntilCreation: 'Unavailable until creation',
        companyOverview: 'Company Overview',
        companyRejected: 'Can not edit if company is rejected',
        businessInfo: 'Business Info',
        businessModel: 'Business Model',
        pointsOfSale: 'Points of Sale',
        tippingReports: 'Tipping Reports',
        features: 'Features',
        historyActivities: 'History Activities'
      }
    },
    businessInfo: {
      businessInfo: 'Business Info',
      businessInformation: 'Business information',
      saveChanges: 'Save Changes',
      reset: 'Reset',
      basicInfo: 'Basic Info',
      fullName: 'Full Name',
      contactEmail: 'Contact Email',
      accountingEmail: 'Accounting Email',
      vat: 'VAT',
      registrationNumber: 'Registration Number',
      locationInfo: 'Location Info',
      address: 'Address',
      zip: 'Zip code',
      city: 'City',
      country: 'Country',
      noPaymentMethods: 'No payment methods selected.',
      noPaymentOptions: 'No payment options selected.',
      noPaymentNetworks: 'No payment networks selected.',
      methodsNoPaymentOptions:
        "Selected payment options don't have processors.",
      methodsNoProcessors: "Selected methods don't have payment processors.",
      optionsNoProcessors: "Selected payment options don't have processors.",
      optionsNoNetworks: "Selected payment options don't have networks."
    },
    businessModel: {
      businessModel: 'Business Model',
      updateBM: 'Update Business information',
      saveChanges: 'Save Changes',
      reset: 'Reset',
      currency: 'Currency',
      currencies: 'Currencies',
      mechantID: 'Merchant ID',
      tipping: 'Tipping report period',
      paymentMethods: 'Payment Methods',
      paymentProcessors: 'Payment Processors',
      paymentMethod: 'Payment Method',
      offline: 'Offline',
      online: 'Online,',
      externalCompaniesIDs: 'External Companies IDs',
      externalCompanyID: 'External Company ID',
      makeSure:
        'Make sure that "Payment Methods" are unique and "External Company IDs" are not empty',
      deleteRow: 'Delete Row',
      addNewRow: 'Add New Row',
      wallets: 'Wallets',
      wallet: 'Wallet',
      externalCompanies: '# of External Companies IDs by Payment Method',
      paymentOptions: 'Payment Options',
      paymentNetworks: 'Payment Networks',
      networkProcessors: 'Payment Network Processors',
      methodProcessors: 'Payment Method Processors',
      optionProcessors: 'Payment Option Processors'
    },
    features: {
      features: 'Features',
      manageAll: 'Manage all companies features at one place'
    },
    history: {
      historyActivities: 'History Activities',
      allChanges: 'All changes ever made to this company object',
      allChangesObject: 'All changes ever made to this point of sale object'
    },
    overview: {
      businessInfo: 'Business Info',
      companyID: 'Company ID',
      companyName: 'Company Name',
      address: 'Address',
      merchantID: 'Merchant ID',
      contactEmail: 'Contact Email',
      accountingEmail: 'Accounting Email',
      vat: 'VAT',
      regNumber: 'Registration Number',
      createdAt: 'Created At',
      approvedAt: 'Approved At',
      posID: 'Point of Sale ID',
      posName: 'Point of Sale Name',
      posType: 'Point of Sale Type',
      websiteURL: 'Website URL',
      language: 'Language',
      timezone: 'Timezone',
      dailyStatement: 'Daily statement time',
      createAt: 'Created At',
      activatedAt: 'Activated At',
      openingHours: 'Opening hours',
      currentLocalTime: 'Current local time',
      posStatus: 'Point of Sale Status',
      currentStatus: 'Current status',
      changePOSStatus: 'Change Point of Sale status',
      deactivatingPOS:
        'Deactivating a point of sale has the following effects:',
      posWillInactive:
        'Point of sale will become inactive, therefor this point of sale can not be edited anymore.',
      posWillDeactivated:
        'devices will become deactivated. All assigned devices will be returned to the storage and can be reused by any other point of sale.',
      activatingPOS: 'Activating a point of sale has the following effects:',
      posWillActive:
        'Point of sale will become active, therefor this point of sale can be edited again.',
      terminatePOS: 'Terminate Point of Sale',
      terminatingPos: 'Terminating a point of sale has the following effects:',
      allPOSRemoved: 'All point of sale data will be removed.',
      devicesRemoved: 'devices will be removed.',
      allPOSCredentialsRemoved:
        'All assigned credentials on this point of sale will be removed.'
    },
    companyStatus: {
      companyStatus: 'Company Status',
      update: 'Update'
    },
    terminatedAlert: {
      statusNotChanged:
        'Company status can not be changed since Company is already'
    },
    companyDelete: {
      terminateCompany: 'Terminate Company',
      effects: 'Terminating a company has the following effects',
      data: 'All company data will be removed.',
      pointsSale: 'points of sale will be removed.',
      devices: 'All assigned devices on this company will be removed.',
      terminate: 'Terminate company'
    },
    pointsOfSale: {
      pointsOfSale: 'Points of Sale',
      newPOS: 'New Point of Sale',
      editPOS: 'Edit Point of Sale'
    },
    tippingReports: {
      tippingReports: 'Tipping Reports',
      filterPOS: '<b>Filter</b> by Point of Sale'
    },
    pushCompanySettings: {
      pushCompanySettings: 'Push company settings',
      pushingToAllDevices: 'Pushing Company settings to all devices...',
      pushNewSettings:
        'Pushing new settings to company devices might have unintended consequences!<br />Push settings only if you know what are you doing.',
      areYouSure:
        'Are you sure that you want to push company settings to all company`s devices?'
    },
    terminateCompany: {
      terminateCompany: 'Terminate Company',
      terminating: 'Company is terminating...',
      cantReverse:
        'Terminating company can not be reversed!<br />Terminate Company only if you know what are you doing.',
      sure: 'Are you sure that you want to terminate company',
      reason: 'Reason',
      termReason: 'Terminate reason',
      reasonEnter: 'Enter your reason for termination'
    },
    updateCompany: {
      updateCompany: 'Update Company',
      areYouSure:
        'Are you sure that you want to update the company that is already',
      reason: 'Reason',
      updateReason: 'Update reason',
      enterReason: 'Enter your reason for company update',
      updateCompanyStatus: 'Update Company status',
      updating: 'Company status is updating...',
      updatingConseq:
        'Updating company status might have unintended consequences!<br />Change Company status only if you know what are you doing.',
      sureUpdate: 'Are you sure that you want to update company status from',
      rejectReason: 'Rejection reason',
      enterRejectReason: 'Enter your reason for rejection'
    },
    device: {
      basicInfo: 'Basic Info',
      name: 'Device Name',
      pos: 'Point of Sale',
      merchantID: 'Merchant ID',
      terminalID: 'Terminal ID',
      serialNumber: 'Serial Number',
      createdAt: 'Created At',
      settings: 'Settings',
      updateInfo: 'Update device information',
      anyType: 'App Type',
      newGenApp: 'New generation configurable app',
      cashRegister: 'Cash Register Info',
      notConnected: 'Not connected',
      pairing: 'Pairing',
      unpair: 'Unpair device',
      unpairDevice: 'Unpairing a device has the following effects:',
      deactivated: 'Device will become deactivated..',
      unpaired: 'Device will become unpaired with the system.',
      newOTP: 'New OTP code will be available.',
      pairDevice: 'Pair device',
      enterOTPAndTerminaID:
        'In order to pair device with the system enter the "OTP" and "Terminal ID below to the device.',
      needApproved:
        'Company have to be APPROVED (or DEMO) and Point of Sale Active!',
      status: 'Device Status',
      changeStatus: 'Change Device status',
      deactivating: 'Deactivating a device has the following effects:',
      deviceInactive:
        'Device will become inactive, therefor this device can not be used anymore.',
      activating: 'Activating a point of sale has the following effects:',
      activeDevice:
        'Device will become active, therefor this point of sale can be used again.',
      deleteDevice: 'Delete Device',
      deletingEffects: 'Deleting a device has the following effects:',
      allRemoved: 'All device data will be removed.',
      paid: 'PAID',
      unpaid: 'UNPAID',
      active: 'ACTIVE',
      inactive: 'INACTIVE',
      active_: 'Active',
      inactive_: 'Inactive'
    },
    pos: {
      overview: 'Point of Sale Overview',
      business_info: 'Business Info',
      openHours: 'Opening hours',
      credentials: 'Credentials',
      devices: 'Devices',
      newDevices: 'New Device',
      historyActivities: 'History Activities',
      businessInfo: 'Business Info',
      businessInformation: 'Business information',
      basicInto: 'Basic Info',
      fullIName: 'Full Name',
      url: 'Website URL',
      language: 'Language',
      locationInfo: 'Location Info',
      address: 'Address',
      zip: 'Zip code',
      city: 'City',
      country: 'Country',
      other: 'Other',
      timezone: 'Timezone',
      dailyStatement: 'Daily statement time',
      paymentMethods: 'Payment Methods',
      wallets: 'Wallets',
      cryptoCurrencies: 'Crypto currencies',
      tipping: 'Tipping',

      enableTipping: 'Enable tipping',
      transactionHistory: 'Transaction history',
      enableTransactionHistory: 'Enable transaction history',
      transactionStatement: 'Transaction statement',
      enableTransactionStatement: 'Enable transaction statement',
      newCredentials: 'New Credentials',
      actions: 'ACTIONS',
      createCredential: 'Create Credential',
      credentialCreating: 'Credential is creating...',
      paymentFlowType: 'Payment Flow Type',
      paymentMethod: 'Payment Method',
      selectPaymentMethod: 'Select Payment method',
      selectPaymentProcessor: 'Select Payment processor',
      noCredentialsField:
        'No possible credentials field found. <br />Try different combination of payment flow type and payment method type.',
      dontShare:
        'Make sure that you don`t share credential information with anybody.',
      createDevice: 'Create Device',
      deviceCreating: 'Device is creating...',
      pos: 'Point of Sale',
      terminalID: 'Terminal ID',
      isExternal: 'Is external',
      newGen: 'New generation configurable app',
      choosePOStype: 'Choose Point of Sale Type',
      offline: 'Offline',
      createOffline: 'Create an Offline Point of Sale',
      online: 'Online',
      createOnline: 'Create an Online Point of Sale',
      deleteCredential: 'Delete Credential',
      deletingCredential: 'Credential is deleting...',
      deletingDevice: 'Device is deleting...',
      deleteNoReverse:
        'Deletion of credential can not be reversed!<br />Delete Credential only if you know what are you doing.',
      deleteNoReverseDevice:
        'Deletion of device can not be reversed!<br />Delete Device only if you know what are you doing.',
      terminatingNoReverse:
        'Terminating point of sale can not be reversed!<br />Terminate Point of Sale only if you know what are you doing.',
      sureDelete: 'Are you sure that you want to delete credential',
      sureDeleteDevice: 'Are you sure that you want to delete device?',
      sureTerminate: 'Are you sure that you want to terminate company',
      deleteDevice: 'Delete Device',
      terminatePos: 'Terminate Point of Sale',
      terminatingPos: 'Point of Sale is terminating...',
      reason: 'Reason',
      updateReason: 'Update reason',
      enterReasonTerminate: 'Enter your reason for termination',
      terminate: 'Terminate',
      terminationReason: 'Termination reason',
      updatePOSStatus: 'Update Point of Sale status',
      posStatusUpdating: 'Point of Sale status is updating...',
      sureUpdatePos:
        'Updating point of sale status might have unintended consequences!<br />Change Point of Sale status only if you know what are you doing.',
      sureSureUpdatePos:
        'Are you sure that you want to update point of sale status from',
      enterReasonUpdate: 'Enter your reason for update',
      paymentProcessor: 'Payment Processor'
    },
    openingHours: {
      opensAt: 'Opens at',
      closesAt: 'Closes at',
      openingHours: 'Opening hours',
      applyAllDays: 'Apply to all days',
      openAllDay: 'Open all day',
      closed: 'Closed',
      closedAllDay: 'Closed all day'
    },
    payout: {
      payoutDetails: 'Payout Details',
      payoutLoading: 'Payout is loading ...',
      downloadInvoice: 'Download Invoice'
    },
    transaction: {
      transactionStatementDetails: 'Transaction Statement Details',
      statementLoading: 'Transaction statement is loading ...',
      transactionDetails: 'Transaction Details',
      transactionLoading: 'Transaction is loading ...'
    },
    auth: {
      forgottenPassword: 'Forgotten Password?',
      enterEmail: 'Enter your email to reset your password',
      emailDetailIncorrect: 'The email detail is incorrect',
      emailCorrect: 'Email is correct!',
      recoverySent:
        'Message with `recovery` instruction<br />has been sent<br />',
      okGotIt: 'Ok, got it!',
      email: 'Email',
      signInToAcc: 'Sign In to your account',
      nothingsMatch: 'These credentials do not match our records.',
      password: 'Password',
      signIn: 'Sign In',
      logoutWorks: 'logout works!',
      enterOTP: 'Enter your OTP Token',
      use2fa: 'Use your Two-factor Authenticator',
      otpNotValid: 'OTP token is not valid.',
      otpToken: 'OTP Token',
      signUp: 'Sign Up',
      ScanQR: 'Scan QR code',
      createAccount: 'Enter your details to create your account',
      registrationIncorrect: 'The registration details are incorrect',
      fullName: 'Fullname',
      fullNameErrors: {
        required: 'Fullname is required',
        short: 'Fullname should have at least 3 symbols',
        long: 'Fullname should have maximum 100 symbols'
      },
      emailErrors: {
        required: 'Email is required',
        invalid: 'Email is invalid',
        short: 'Email should have at least 3 symbols',
        long: 'Email should have maximum 360 symbols'
      },
      pass: 'Password',
      passErrors: {
        required: 'Password is required',
        short: 'Password should have at least 3 symbols',
        long: 'Password should have maximum 100 symbols'
      },
      confirmPass: 'Confirm Password',
      confirmPassErrors: {
        required: 'Confirm Password is required',
        short: 'Confirm Password should have at least 3 symbols',
        long: 'Confirm Password should have maximum 100 symbols'
      },
      confirmPassNoMatch: '`Passsword` and `Confirm Password` didn`t match.',
      agree:
        'Agree the <a href="https://keenthemes.com/metronic/?page=faq" target="_blank" >terms and conditions</a >.'
    },
    feature_request: {
      approve: 'Approve Feature request',
      sure: 'Are you sure that you want to approve feature request and change this feature`s status from',
      pending: 'Pending',
      to: 'to',
      active: 'Active',
      disable: 'Disable Feature',
      sureDisable0: 'Are you sure that you want to disable feature',
      sureDisable1: 'and change this feature`s status from',
      merchantRequest0: 'Merchant requested on',
      merchantRequest1: 'to enable this feature.'
    },
    /*Never in code seen*/

    AUTH: {
      GENERAL: {
        OR: 'Or',
        SUBMIT_BUTTON: 'Submit',
        NO_ACCOUNT: 'Don`t have an account?',
        SIGNUP_BUTTON: 'Sign Up',
        FORGOT_BUTTON: 'Forgot Password',
        BACK_BUTTON: 'Back',
        PRIVACY: 'Privacy',
        LEGAL: 'Legal',
        CONTACT: 'Contact'
      },
      LOGIN: {
        TITLE: 'Login Account',
        BUTTON: 'Sign In'
      },
      FORGOT: {
        TITLE: 'Forgotten Password?',
        DESC: 'Enter your email to reset your password',
        SUCCESS: 'Your account has been successfully reset.'
      },
      REGISTER: {
        TITLE: 'Sign Up',
        DESC: 'Enter your details to create your account',
        SUCCESS: 'Your account has been successfuly registered.'
      },
      INPUT: {
        EMAIL: 'Email',
        FULLNAME: 'Fullname',
        PASSWORD: 'Password',
        CONFIRM_PASSWORD: 'Confirm Password',
        USERNAME: 'Username'
      },
      VALIDATION: {
        INVALID: '{{name}} is not valid',
        REQUIRED: '{{name}} is required',
        MIN_LENGTH: '{{name}} minimum length is {{min}}',
        AGREEMENT_REQUIRED: 'Accepting terms & conditions are required',
        NOT_FOUND: 'The requested {{name}} is not found',
        INVALID_LOGIN: 'The login detail is incorrect',
        REQUIRED_FIELD: 'Required field',
        MIN_LENGTH_FIELD: 'Minimum field length:',
        MAX_LENGTH_FIELD: 'Maximum field length:',
        INVALID_FIELD: 'Field is not valid'
      }
    },
    ECOMMERCE: {
      COMMON: {
        SELECTED_RECORDS_COUNT: 'Selected records count: ',
        ALL: 'All',
        SUSPENDED: 'Suspended',
        ACTIVE: 'Active',
        FILTER: 'Filter',
        BY_STATUS: 'by Status',
        BY_TYPE: 'by Type',
        BUSINESS: 'Business',
        INDIVIDUAL: 'Individual',
        SEARCH: 'Search',
        IN_ALL_FIELDS: 'in all fields'
      },
      ECOMMERCE: 'eCommerce',
      CUSTOMERS: {
        CUSTOMERS: 'Customers',
        CUSTOMERS_LIST: 'Customers list',
        NEW_CUSTOMER: 'New Customer',
        DELETE_CUSTOMER_SIMPLE: {
          TITLE: 'Customer Delete',
          DESCRIPTION: 'Are you sure to permanently delete this customer?',
          WAIT_DESCRIPTION: 'Customer is deleting...',
          MESSAGE: 'Customer has been deleted'
        },
        DELETE_CUSTOMER_MULTY: {
          TITLE: 'Customers Delete',
          DESCRIPTION: 'Are you sure to permanently delete selected customers?',
          WAIT_DESCRIPTION: 'Customers are deleting...',
          MESSAGE: 'Selected customers have been deleted'
        },
        UPDATE_STATUS: {
          TITLE: 'Status has been updated for selected customers',
          MESSAGE: 'Selected customers status have successfully been updated'
        },
        EDIT: {
          UPDATE_MESSAGE: 'Customer has been updated',
          ADD_MESSAGE: 'Customer has been created'
        }
      }
    }
  }
};
