import { AuthModel } from './auth.model';
import { AddressModel } from './address.model';
import { SocialNetworksModel } from './social-networks.model';
import { Group } from './group';
import { Permission } from './permission';

export class UserModel extends AuthModel {
    id: number;
    username: string;
    password: string;
    fullname: string;
    email: string;
    pic: string;
    roles: number[];
    occupation: string;
    companyName: string;
    phone: string;
    address?: AddressModel;
    socialNetworks?: SocialNetworksModel;
    // personal information
    firstname: string;
    lastname: string;
    website: string;
    // account information
    language: string;
    timeZone: string;
    communication: {
        email: boolean;
        sms: boolean;
        phone: boolean;
    };
    // email settings
    emailSettings: {
        emailNotification: boolean;
        sendCopyToPersonalEmail: boolean;
        activityRelatesEmail: {
            youHaveNewNotifications: boolean;
            youAreSentADirectMessage: boolean;
            someoneAddsYouAsAsAConnection: boolean;
            uponNewOrder: boolean;
            newMembershipApproval: boolean;
            memberRegistration: boolean;
        };
        updatesFromKeenthemes: {
            newsAboutKeenthemesProductsAndFeatureUpdates: boolean;
            tipsOnGettingMoreOutOfKeen: boolean;
            thingsYouMissedSindeYouLastLoggedIntoKeen: boolean;
            newsAboutMetronicOnPartnerProductsAndOtherServices: boolean;
            tipsOnMetronicBusinessProducts: boolean;
        };
    };

    isActive: boolean;
    dateJoined: Date;
    lastLogin: Date;
    groups: Group[];
    permissions: Permission[];
    userPermissions: Permission[];
    allowedCountries: string[];

    setUser(user: any) {
        this.id = user.id;
        this.username = user.username || '';
        this.password = user.password || '';
        this.email = user.email || '';
        this.pic = user.pic || './assets/media/users/default.jpg';
        this.roles = user.roles || [];
        this.occupation = user.occupation || '';
        this.companyName = user.companyName || '';
        this.phone = user.phone || '';
        this.address = user.address;
        this.socialNetworks = user.socialNetworks;

        this.firstname = user.first_name || '';
        this.lastname = user.last_name || '';
        this.fullname = `${this.firstname} ${this.lastname}`;

        this.isActive = !!user.is_active;
        this.dateJoined = user.date_joined;
        this.lastLogin = user.last_login;

        this.groups = user.groups;
        this.permissions = user.permissions;
        this.userPermissions = user.user_permissions;
        this.allowedCountries = user.allowed_countries;
    }
}
