import { Injectable, Injector } from '@angular/core';
import { Observable, of } from 'rxjs';
import { UserModel } from '../..';
import { environment } from '../../../../../environments/environment';
import { catchError } from 'rxjs/operators';
import { AuthModel } from '../../_models/auth.model';
import { AuthService } from '../auth.service';
import { LoginCredentials } from '../../_models/login-credentials.models';
import { fetchObservable } from '../../../../_metronic/shared/utils.service';
import { MultiTenantService } from '../../multi-tenant.service';

const API_AUTH_URL = `auth`;
const API_USERS_URL = `admin/users`;

@Injectable({
    providedIn: 'root',
})
export class AuthHTTPService {
    private authLocalStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}`;
    private isRefreshingToken = false;

    constructor(private injector: Injector, private multiTenantService: MultiTenantService) {}

    // public methods
    login(credentials: LoginCredentials): Observable<any> {
        const host = this.multiTenantService.apiHost;
        const url = `${host}/${API_AUTH_URL}/token/`;
        const clientId = this.multiTenantService.selectedTenant?.client_id;

        const body = {
            username: credentials.email,
            password: credentials.password,
            otp_token: credentials.otpToken,
            client_id: clientId,
            grant_type: 'password',
        };

        return fetchObservable('POST', url, JSON.stringify(body));
    }

    // CREATE =>  POST: add a new user to the server
    createUser(user: UserModel): Observable<UserModel> {
        const host = this.multiTenantService.apiHost;
        const url = `${host}/${API_AUTH_URL}/`;
        // Not yet implemented
        return fetchObservable('POST', url, JSON.stringify(user));
    }

    // Your server should check email => If email exists send link to the user and return true | If email doesn't exist return false
    forgotPassword(email: string): Observable<boolean> {
        const host = this.multiTenantService.apiHost;
        const url = `${host}/${API_AUTH_URL}/forgot-password/`;
        // Not yet implemented
        return fetchObservable('POST', url, JSON.stringify({ email }));
    }

    refreshToken(token: string): Observable<any> {
        const host = this.multiTenantService.apiHost;
        const url = `${host}/${API_AUTH_URL}/token/`;
        const clientId = this.multiTenantService.selectedTenant?.client_id;

        const body = {
            refresh_token: token,
            client_id: clientId,
            grant_type: 'refresh_token',
        };

        return fetchObservable('POST', url, JSON.stringify(body));
    }

    getUserByToken(auth: AuthModel): Observable<UserModel> {
        const host = this.multiTenantService.apiHost;
        const url = `${host}/${API_USERS_URL}/me/`;
        const headers = new Headers({
            Authorization: `Bearer ${auth.authToken}`,
        });

        return fetchObservable('GET', url, null, headers).pipe(
            catchError((response: any) => {
                if (response instanceof Response && (response as Response).status === 401) {
                    if (!this.isRefreshingToken) {
                        this.isRefreshingToken = true;
                        this.refreshToken(auth.refreshToken).subscribe((data: any) => {
                            const newAuth: AuthModel = new AuthModel(data);

                            if (newAuth && newAuth.authToken) {
                                localStorage.setItem(this.authLocalStorageToken, JSON.stringify(newAuth));
                            }

                            const authService = this.injector.get(AuthService);

                            authService.getUserByToken();
                            this.isRefreshingToken = false;
                        });
                    }
                }
                return of('skip');
            })
        );
    }
}
