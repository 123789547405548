import { Component, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { TranslationService } from './modules/i18n/translation.service';
import { Title } from '@angular/platform-browser';

// language list
import { locale as enLang } from './modules/i18n/vocabs/en';
import { locale as chLang } from './modules/i18n/vocabs/ch';
import { locale as esLang } from './modules/i18n/vocabs/es';
import { locale as jpLang } from './modules/i18n/vocabs/jp';
import { locale as deLang } from './modules/i18n/vocabs/de';
import { locale as frLang } from './modules/i18n/vocabs/fr';
import { SplashScreenService } from './_metronic/partials/layout/splash-screen/splash-screen.service';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { TableExtendedService } from './_metronic/shared/crud-table';
import { MultiTenantService } from './modules/auth/multi-tenant.service';
import { Tenant } from './modules/auth/_models/tenant.model';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'body[root]',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnDestroy {
    private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

    constructor(
        private translationService: TranslationService,
        private splashScreenService: SplashScreenService,
        private multiTenantService: MultiTenantService,
        private router: Router,
        private tableService: TableExtendedService,
        private titleService: Title
    ) {
        // register translations
        this.translationService.loadTranslations(enLang, chLang, esLang, jpLang, deLang, frLang);

        const routerSubscription = this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                // clear filtration paginations and others
                this.tableService.setDefaults();
                // hide splash screen
                this.splashScreenService.hide();

                // scroll to top on every route change
                window.scrollTo(0, 0);

                // to display back the body content
                setTimeout(() => {
                    document.body.classList.add('page-loaded');
                }, 500);
            }
        });

        const multiTenantSubscription = this.multiTenantService.tenant$$.subscribe((tenant: Tenant) => {
            this.titleService.setTitle(`${tenant?.display_name} - ${this.titleService.getTitle()}`);
        });

        this.unsubscribe.push(routerSubscription);
        this.unsubscribe.push(multiTenantSubscription);
    }

    ngOnDestroy() {
        this.unsubscribe.forEach((sb) => sb.unsubscribe());
    }
}
