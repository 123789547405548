// tslint:disable:variable-name
import {
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpResponse
} from '@angular/common/http';
import {BehaviorSubject, Observable, of, Subscription, throwError} from 'rxjs';
import {catchError, finalize, tap} from 'rxjs/operators';
import {PaginatorState} from '..';
import {ITableState, TableResponseModel} from '..';
import {BaseModel} from '..';
import {SortState} from '..';
import {GroupingState} from '..';
import {AuthService} from '../../../../modules/auth';
import {downloadFile} from '../../utils.service';
import {MultiTenantService} from '../../../../modules/auth/multi-tenant.service';

const DEFAULT_STATE: ITableState = {
  filter: {},
  paginator: new PaginatorState(),
  sorting: new SortState(),
  searchTerm: '',
  grouping: new GroupingState(),
  entityId: undefined
};

export abstract class TableService<T> {
  // Private fields
  private _items$ = new BehaviorSubject<T[]>([]);
  private _isLoading$ = new BehaviorSubject<boolean>(false);
  private _isFirstLoading$ = new BehaviorSubject<boolean>(true);
  private _tableState$ = new BehaviorSubject<ITableState>(DEFAULT_STATE);
  private _errorMessage = new BehaviorSubject<string>('');
  private _subscriptions: Subscription[] = [];

  // Getters
  get items$() {
    return this._items$.asObservable();
  }
  get isLoading$() {
    return this._isLoading$.asObservable();
  }
  get isFirstLoading$() {
    return this._isFirstLoading$.asObservable();
  }
  get errorMessage$() {
    return this._errorMessage.asObservable();
  }
  get subscriptions() {
    return this._subscriptions;
  }
  // State getters
  get paginator() {
    return this._tableState$.value.paginator;
  }
  get filter() {
    return this._tableState$.value.filter;
  }
  get sorting() {
    return this._tableState$.value.sorting;
  }
  get searchTerm() {
    return this._tableState$.value.searchTerm;
  }
  get grouping() {
    return this._tableState$.value.grouping;
  }

  protected http: HttpClient;
  protected authService: AuthService;
  protected multiTenantService: MultiTenantService;

  // API URL has to be overridden
  API_URL = `endpoint/`;

  constructor(
    http: HttpClient,
    authService: AuthService,
    multiTenantService: MultiTenantService
  ) {
    this.http = http;
    this.authService = authService;
    this.multiTenantService = multiTenantService;
  }

  // CREATE
  // server should return the object with ID
  create(item: T | Partial<T>): Observable<T> | any {
    const host = this.multiTenantService.apiHost;
    const url = `${host}/${this.API_URL}`;
    this._isLoading$.next(true);
    this._errorMessage.next('');
    return this.http.post<T>(url, item).pipe(
      catchError((err) => {
        this._errorMessage.next(err);
        console.error('CREATE ITEM', err);
        return throwError(err);
      }),
      finalize(() => this._isLoading$.next(false))
    );
  }

  // READ (Returning filtered list of entities)
  find(tableState: ITableState): Observable<TableResponseModel<T>> {
    const host = this.multiTenantService.apiHost;
    const url = `${host}/${this.API_URL}`;
    this._errorMessage.next('');

    const params = this.extractQueryParamsFromState(tableState);

    return this.http.get<TableResponseModel<T>>(url, {params}).pipe(
      catchError((err) => {
        this._errorMessage.next(err);
        console.error('FIND ITEMS', err);
        return of({
          results: [],
          count: 0,
          previous: null,
          next: null
        });
      })
    );
  }

  getItemById(id: string): Observable<T> | any {
    this._isLoading$.next(true);
    this._errorMessage.next('');
    const host = this.multiTenantService.apiHost;
    const url = `${host}/${this.API_URL}${id}/`;
    return this.http.get<T>(url).pipe(
      catchError((err) => {
        this._errorMessage.next(err);
        console.error('GET ITEM BY IT', id, err);
        return throwError(err);
      }),
      finalize(() => this._isLoading$.next(false))
    );
  }

  // UPDATE
  update(item: T | Partial<T>): Observable<T> | Observable<any> {
    const host = this.multiTenantService.apiHost;
    const url = `${host}/${this.API_URL}${(item as any).id}/`;
    this._isLoading$.next(true);
    this._errorMessage.next('');
    return this.http.patch(url, item).pipe(
      catchError((err) => {
        this._errorMessage.next(err);
        console.error('UPDATE ITEM', item, err);
        return throwError(err);
      }),
      finalize(() => this._isLoading$.next(false))
    );
  }

  // UPDATE Status
  updateStatusForItems(ids: number[], status: number): Observable<any> {
    this._isLoading$.next(true);
    this._errorMessage.next('');
    const host = this.multiTenantService.apiHost;
    const url = `${host}/${this.API_URL}updateStatus/`;
    const body = {ids, status};
    return this.http.put(url, body).pipe(
      catchError((err) => {
        this._errorMessage.next(err);
        console.error('UPDATE STATUS FOR SELECTED ITEMS', ids, status, err);
        return throwError(err);
      }),
      finalize(() => this._isLoading$.next(false))
    );
  }

  // DELETE
  delete(id: string): Observable<any> {
    this._isLoading$.next(true);
    this._errorMessage.next('');
    const host = this.multiTenantService.apiHost;
    const url = `${host}/${this.API_URL}${id}`;
    return this.http.delete(url).pipe(
      catchError((err) => {
        this._errorMessage.next(err);
        console.error('DELETE ITEM', id, err);
        return throwError(err);
      }),
      finalize(() => this._isLoading$.next(false))
    );
  }

  // delete list of results
  deleteItems(ids: string[] = []): Observable<any> {
    this._isLoading$.next(true);
    this._errorMessage.next('');
    const host = this.multiTenantService.apiHost;
    const url = `${host}/${this.API_URL}deleteItems/`;
    const body = {ids};
    return this.http.put(url, body).pipe(
      catchError((err) => {
        this._errorMessage.next(err);
        console.error('DELETE SELECTED ITEMS', ids, err);
        return throwError(err);
      }),
      finalize(() => this._isLoading$.next(false))
    );
  }

  export(format: any, filename: string = 'export'): Observable<any> {
    const host = this.multiTenantService.apiHost;
    const url = `${host}/${this.API_URL}export/${format}/`;

    let params = new HttpParams();
    const state = this._tableState$.value;

    // Filters
    for (const [key, values] of Object.entries(state.filter)) {
      (values as string[]).forEach(
        (v) => (params = params.append(key, `${v}`))
      );
    }

    // Search
    const searchTerm = state.searchTerm.trim();
    if ((searchTerm || '').length > 0) {
      params = params.append('search', searchTerm);
    }

    const headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    return this.http
      .get(url, {
        headers,
        params,
        observe: 'response',
        responseType: 'blob'
      })
      .pipe(
        tap((response: HttpResponse<Blob>) =>
          downloadFile(response, `${filename}.${format}`)
        )
      );
  }

  public fetch() {
    this._isLoading$.next(true);
    this._errorMessage.next('');
    const request = this.find(this._tableState$.value)
      .pipe(
        tap((res: TableResponseModel<T>) => {
          this._items$.next(res.results);
          this.patchStateWithoutFetch({
            paginator: this._tableState$.value.paginator.recalculatePaginator(
              res.count
            )
          });
        }),
        catchError((err) => {
          this._errorMessage.next(err);
          return of({
            items: [],
            total: 0
          });
        }),
        finalize(() => {
          this._isLoading$.next(false);
          const itemIds = this._items$.value.map((el: T) => {
            const item = el as unknown as BaseModel;
            return item.id;
          });
          this.patchStateWithoutFetch({
            grouping: this._tableState$.value.grouping.clearRows(itemIds)
          });
        })
      )
      .subscribe();
    this._subscriptions.push(request);
  }

  public setDefaults() {
    this.patchStateWithoutFetch({filter: {}});
    this.patchStateWithoutFetch({sorting: new SortState()});
    this.patchStateWithoutFetch({grouping: new GroupingState()});
    this.patchStateWithoutFetch({searchTerm: ''});
    this.patchStateWithoutFetch({
      paginator: new PaginatorState()
    });
    this._isFirstLoading$.next(true);
    this._isLoading$.next(true);
    this._tableState$.next(DEFAULT_STATE);
    this._errorMessage.next('');
  }

  // Base Methods
  public patchState(patch: Partial<ITableState>) {
    this.patchStateWithoutFetch(patch);
    this.fetch();
  }

  public patchStateWithoutFetch(patch: Partial<ITableState>) {
    const newState = Object.assign(this._tableState$.value, patch);
    this._tableState$.next(newState);
  }

  private extractQueryParamsFromState(state: ITableState): HttpParams {
    let params = new HttpParams();

    // Sorting
    const orderDirection = state.sorting.direction === 'desc' ? '-' : '';
    const orderColumn = state.sorting.column;
    params = params.append('ordering', `${orderDirection}${orderColumn}`);

    // Search
    const searchTerm = state.searchTerm.trim();
    if ((searchTerm || '').length > 0) {
      params = params.append('search', searchTerm);
    }

    // Filters
    for (const [key, values] of Object.entries(state.filter)) {
      (values as string[]).forEach(
        (v) => (params = params.append(key, `${v}`))
      );
    }

    // Page size
    const pageSize = state.paginator.pageSize;
    params = params.append('page_size', `${pageSize}`);

    // Page
    const page = state.paginator.page;
    params = params.append('page', `${page}`);

    return params;
  }
}
