import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Tenant } from './_models/tenant.model';
import { fetchObservable, getHostFromUrl } from '../../_metronic/shared/utils.service';

const API_MULTI_TENANT_URL = environment.apiUrl;
const SELECTED_TENANT_LOCAL_STORAGE_KEY = 'selected_multi_tenant';

@Injectable({
    providedIn: 'root',
})
export class MultiTenantService {
    tenant$$: BehaviorSubject<Tenant> = new BehaviorSubject<Tenant>(null);
    private _selectedTenant?: Tenant;

    get selectedTenant(): Tenant | null {
        return this._selectedTenant ?? this.getTenantFromLocalStorage();
    }

    get apiHost(): string {
        return this.selectedTenant?.api_endpoint;
    }

    constructor() {
        this.fetchTenant().subscribe(
            (data: Tenant) => {
                if (data == null || !('host' in data)) {
                    this.tenant$$.next(null);
                    this._selectedTenant = null;
                    localStorage.removeItem(SELECTED_TENANT_LOCAL_STORAGE_KEY);
                } else {
                    this.tenant$$.next(data);
                    this._selectedTenant = this.tenant$$.value;
                    this.writeTenantToLocalStorage(this._selectedTenant);
                }
            },
            (err) => console.log(err)
        );
    }

    fetchTenant(): Observable<Tenant> {
        const url = `${API_MULTI_TENANT_URL}/config/admin/`;
        const ellyPosHost: string = getHostFromUrl();
        const headers: Headers = new Headers();
        headers.append('X-ADMIN-HOST', ellyPosHost);
        return fetchObservable<Tenant>('GET', url, null, headers);
    }

    // private methods
    private getTenantFromLocalStorage(): Tenant {
        return JSON.parse(localStorage.getItem(SELECTED_TENANT_LOCAL_STORAGE_KEY));
    }

    private writeTenantToLocalStorage(tenant: Tenant): void {
        localStorage.setItem(SELECTED_TENANT_LOCAL_STORAGE_KEY, JSON.stringify(tenant));
    }
}
