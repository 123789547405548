import { Injectable, Injector, OnDestroy } from '@angular/core';
import { Observable, BehaviorSubject, of, Subscription } from 'rxjs';
import { map, catchError, switchMap, finalize, tap } from 'rxjs/operators';
import { UserModel } from '../_models/user.model';
import { AuthModel } from '../_models/auth.model';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { AuthHTTPService } from './auth-http';
import { Country } from '../../common/models/country.model';
import { LoginCredentials } from '../_models/login-credentials.models';

@Injectable({
    providedIn: 'root',
})
export class AuthService implements OnDestroy {
    // private fields
    private unsubscribe: Subscription[] = [];
    private authLocalStorageToken = `${environment.appVersion}-${environment.USERDATA_KEY}`;
    private loginCredentials$$: BehaviorSubject<LoginCredentials> = new BehaviorSubject<LoginCredentials>(null);

    // public fields
    currentUser$: Observable<UserModel>;
    isLoading$: Observable<boolean>;
    currentUserSubject: BehaviorSubject<UserModel>;
    isLoadingSubject: BehaviorSubject<boolean>;

    get currentUserValue(): UserModel {
        return this.currentUserSubject.value;
    }

    set currentUserValue(user: UserModel) {
        this.currentUserSubject.next(user);
    }

    get currentLoginCredentials(): LoginCredentials {
        return this.loginCredentials$$.value;
    }

    constructor(private injector: Injector, private authHttpService: AuthHTTPService) {
        this.isLoadingSubject = new BehaviorSubject<boolean>(false);
        this.currentUserSubject = new BehaviorSubject<UserModel>(undefined);
        this.currentUser$ = this.currentUserSubject.asObservable();
        this.isLoading$ = this.isLoadingSubject.asObservable();
        const subscr = this.getUserByToken().subscribe();
        this.unsubscribe.push(subscr);
    }

    // public methods
    login(credentials: Partial<LoginCredentials>): Observable<UserModel> {
        this.isLoadingSubject.next(true);

        let loginCredentials: LoginCredentials = this.currentLoginCredentials || {};
        loginCredentials = { ...loginCredentials, ...credentials };

        this.loginCredentials$$.next(loginCredentials);

        return this.authHttpService.login(loginCredentials).pipe(
            map((data: any) => {
                if (data != null && ('error' in data || 'otp_auth' in data)) {
                    return data;
                }

                const auth: AuthModel = new AuthModel(data);
                return this.setAuthToLocalStorage(auth);
            }),
            switchMap((data: any) => {
                if (data != null && typeof data !== 'boolean' && ('error' in data || 'otp_auth' in data)) {
                    return of(data);
                }

                this.loginCredentials$$.next(null);
                return this.getUserByToken();
            }),
            catchError((err) => {
                console.error('err', err);
                return of(undefined);
            }),
            finalize(() => {
                this.isLoadingSubject.next(false);
            })
        );
    }

    logout() {
        localStorage.removeItem(this.authLocalStorageToken);
        this.isLoadingSubject.next(false);
        const router = this.injector.get(Router);
        router.navigate(['/auth/login'], {
            queryParams: {},
        });
    }

    getUserByToken(): Observable<UserModel> {
        const auth = this.getAuthFromLocalStorage();
        if (!auth || !auth.authToken) {
            return of(undefined);
        }

        this.isLoadingSubject.next(true);
        return this.authHttpService.getUserByToken(auth).pipe(
            map((data: any) => {
                if (data === 'skip') {
                    return {} as UserModel;
                }

                if (data == null) {
                    this.logout();
                }

                const user = new UserModel({});
                user.setUser(data);

                if (user) {
                    this.currentUserSubject = new BehaviorSubject<UserModel>(user);

                    // this.currentUserSubject.next(user);
                } else {
                    this.logout();
                }
                return user;
            }),
            finalize(() => this.isLoadingSubject.next(false))
        );
    }

    // need create new user then login
    registration(user: UserModel): Observable<any> {
        this.isLoadingSubject.next(true);
        return this.authHttpService.createUser(user).pipe(
            map(() => {
                this.isLoadingSubject.next(false);
            }),
            switchMap(() => this.login({ email: user.email, password: user.password })),
            catchError((err) => {
                console.error('err', err);
                return of(undefined);
            }),
            finalize(() => this.isLoadingSubject.next(false))
        );
    }

    forgotPassword(email: string): Observable<boolean> {
        this.isLoadingSubject.next(true);
        return this.authHttpService.forgotPassword(email).pipe(finalize(() => this.isLoadingSubject.next(false)));
    }

    refreshToken(): Observable<AuthModel> {
        const auth = this.getAuthFromLocalStorage();
        if (!auth || !auth.authToken) {
            return of(undefined);
        }

        return this.authHttpService.refreshToken(auth.refreshToken).pipe(
            map((data: any) => {
                const newAuth: AuthModel = new AuthModel(data);
                this.setAuthToLocalStorage(newAuth);
                return newAuth;
            }),
            tap(() => this.getUserByToken()),
            catchError((err) => {
                console.error('err', err);
                return of(undefined);
            }),
            finalize(() => this.isLoadingSubject.next(false))
        );
    }

    getAuth$(): Observable<AuthModel> {
        const auth: AuthModel = this.getAuthFromLocalStorage();
        return of(auth);
    }

    // private methods
    private setAuthToLocalStorage(auth: AuthModel): boolean {
        // store auth authToken/refreshToken/expiresIn in local storage to keep user logged in between page refreshes
        if (auth && auth.authToken) {
            localStorage.setItem(this.authLocalStorageToken, JSON.stringify(auth));
            return true;
        }
        return false;
    }

    private getAuthFromLocalStorage(): AuthModel {
        try {
            const authData = JSON.parse(localStorage.getItem(this.authLocalStorageToken));
            return authData;
        } catch (error) {
            console.error(error);
            return undefined;
        }
    }

    filterAllowedCountries(countries: Country[]): Country[] {
        const allowedCountryCodes = this.currentUserValue.allowedCountries;
        if (allowedCountryCodes.length === 0) {
            return countries;
        }
        return countries.filter((c) => allowedCountryCodes.includes(c.a2_iso_code));
    }

    ngOnDestroy() {
        this.unsubscribe.forEach((sb) => sb.unsubscribe());
    }
}
