import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TableService } from './table.service';
import { AuthService } from '../../../../modules/auth';
import { MultiTenantService } from '../../../../modules/auth/multi-tenant.service';

@Injectable({
    providedIn: 'root',
})
export class TableExtendedService extends TableService<any> {
    constructor(
        @Inject(HttpClient) http,
        @Inject(AuthService) authService,
        @Inject(MultiTenantService) multiTenantService
    ) {
        super(http, authService, multiTenantService);
    }
}
