import { Injectable, Injector } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpResponse,
    HttpErrorResponse,
    HttpSentEvent,
    HttpHeaderResponse,
    HttpProgressEvent,
    HttpUserEvent,
} from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, finalize, switchMap, take } from 'rxjs/operators';
import { AuthModel } from '../_models/auth.model';
import { AuthService } from './auth.service';
import { ToastrService } from 'ngx-toastr';

@Injectable({
    providedIn: 'root',
})
export class TokenInterceptor implements HttpInterceptor {
    isRefreshingToken = false;
    tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

    constructor(private injector: Injector, private toastr: ToastrService) {}

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<
        | HttpSentEvent
        | HttpHeaderResponse
        | HttpProgressEvent
        | HttpResponse<any>
        | HttpUserEvent<any>
        | HttpEvent<any>
        | any
    > {
        const authService: AuthService = this.injector.get(AuthService);
        return authService.getAuth$().pipe(
            switchMap((authModel: AuthModel) => {
                return next.handle(this.addTokenToRequest(request, authModel?.authToken)).pipe(
                    catchError((err) => {
                        if (err instanceof HttpErrorResponse) {
                            if (err.status !== 401) {
                                this.toastr.error(
                                    err.error.message || 'Unknown error. Contact administrator',
                                    'Request failed'
                                );
                            }
                            switch ((err as HttpErrorResponse).status) {
                                case 401:
                                    return this.handle401Error(request, next);
                            }
                        }
                        return throwError(err);
                    })
                );
            })
        );
    }

    private addTokenToRequest(request: HttpRequest<any>, token: string): HttpRequest<any> {
        return request.clone({
            setHeaders: { Authorization: `Bearer ${token}` },
        });
    }

    private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
        if (!this.isRefreshingToken) {
            this.isRefreshingToken = true;

            // Reset here so that the following requests wait until the token
            // comes back from the refreshToken call.
            this.tokenSubject.next(null);

            const authService: AuthService = this.injector.get(AuthService);

            return authService.refreshToken().pipe(
                switchMap((authModel: AuthModel) => {
                    if (authModel.authToken) {
                        this.tokenSubject.next(authModel.authToken);
                        return next.handle(this.addTokenToRequest(request, authModel.authToken));
                    }

                    return authService.logout() as any;
                }),
                catchError((err) => {
                    return authService.logout() as any;
                }),
                finalize(() => {
                    this.isRefreshingToken = false;
                })
            );
        } else {
            this.isRefreshingToken = false;

            return this.tokenSubject.pipe(
                filter((token) => token != null),
                take(1),
                switchMap((token) => {
                    return next.handle(this.addTokenToRequest(request, token));
                })
            );
        }
    }
}
